import { createSlice } from '@reduxjs/toolkit';
import { fetchTikTok } from './tikTokApi';

const initialState = {
  videoList: [],
  isLoading: false,
};

const tikTokSlice = createSlice({
  name: 'tikTok',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTikTok.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTikTok.fulfilled, (state, { payload }) => {
        state.videoList = payload;
      })
      .addCase(fetchTikTok.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const tikTokReducer = tikTokSlice.reducer;
