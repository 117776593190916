import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchTikTok = createAsyncThunk('tikTok/fetchTikTok', async (_, { rejectWithValue }) => {
  try {
    // const params = {};

    const response = await axios.get(
      `https://www.tiktok.com/@eva.car.vin/v2/video/list/?fields=cover_image_url,id,title`
    );
    const data = response.data;
    console.log('data', data);

    return data;
  } catch (error) {
    console.error('Ошибка при загрузке товаров:', error);
    return rejectWithValue(error.response?.data || 'Произошла ошибка при загрузке списка видосов');
  }
});
