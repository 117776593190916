import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequestParams, resourceUrl } from '../../api/getRequestParams';

export const fetchProductsBySearch = createAsyncThunk(
  'products/fetchProductsBySearch',
  async ({ query }, { rejectWithValue }) => {
    try {
      const params = getRequestParams();

      let url;

      // Если запрос выглядит как SKU, используем параметр sku
      if (/^[a-zA-Z0-9_-]+$/.test(query)) {
        url = `${resourceUrl}/wc/v3/products?sku=${query}&_fields=id,name,price,sku,slug,categories&per_page=100`;
      } else {
        // Иначе используем стандартный поиск
        url = `${resourceUrl}/wc/v3/products?search=${query}&_fields=id,name,price,sku,slug,categories&per_page=100`;
      }

      const response = await axios.get(url, { params });

      const data = response.data.map(({ id, name, price, sku, slug, categories }) => ({
        id,
        name,
        price,
        sku,
        slug,
        categories,
      }));

      console.log('data', data);

      return data;
    } catch (error) {
      console.error('Ошибка при поиске товаров:', error);
      return rejectWithValue(error.response?.data || 'Ошибка при выполнении поиска');
    }
  }
);
