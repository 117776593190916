import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import CatalogGrid from './pages/Catalog/CatalogGrid';
// import Layout from './components/Layout/Layout';

const Layout = lazy(() => import('./components/Layout/Layout'));
const Constructor = lazy(() => import('./pages/Constructor/Constructor'));
const Categories = lazy(() => import('./pages/Categories/Categories'));
const Products = lazy(() => import('./pages/Products/Products'));
const ProductLayout = lazy(() => import('./pages/ProductLayout/ProductLayout'));
const Delivery = lazy(() => import('./pages/textPages/Delivery/Delivery'));
const Guarantees = lazy(() => import('./pages/textPages/Guarantees'));
const Partners = lazy(() => import('./pages/textPages/Partners'));
const Exchange = lazy(() => import('./pages/textPages/Exchange'));
const Catalog = lazy(() => import('./pages/Catalog/Catalog'));
const Patents = lazy(() => import('./pages/Patents/Patents'));
const Page404 = lazy(() => import('./pages/Page404/Page404'));
const About = lazy(() => import('./pages/textPages/About'));
const Basket = lazy(() => import('./pages/Basket/Basket'));
const Models = lazy(() => import('./pages/Cars/Models'));
const Home = lazy(() => import('./pages/Home'));

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/catalog" element={<Catalog />}>
          <Route path="/catalog" element={<CatalogGrid />} />
          <Route path="/catalog/:brandName" element={<Models />} />
        </Route>

        <Route path="/accessories" element={<Categories />}>
          {/* Главный список товаров */}
          <Route path=":slug/:categoriesId" element={<Products />} />

          {/* Список товаров из подкатегории */}
          <Route path=":slug/:categoriesId/:subSlug/:subCategoryId" element={<Products />} />

          {/* Карточка товара */}
          <Route path="/accessories/:slug&card/:cardId/:productSlug" element={<ProductLayout />} />

          {/* Карточка товара из подкатегории */}
          <Route path="/accessories/:slug/:subSlug&card/:cardId/:productSlug" element={<ProductLayout />} />
        </Route>

        <Route path="/partners" element={<Partners />} />
        <Route path="/exchange" element={<Exchange />} />
        <Route path="/patents" element={<Patents />} />
        <Route path="/guarantees" element={<Guarantees />} />
        <Route path="/delivery" element={<Delivery />} />

        <Route path="/constructor" element={<Constructor />} />
        <Route path="/constructor/:titleKey/:modelId" element={<Constructor />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
