// src/swRegistration.js
export default function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker
                .register("./sw.js")
                .then((registration) => {
                    // console.log("Service Worker зарегистрирован:", registration.scope);

                    // Автоматическое обновление страницы, если новая версия Service Worker активирована
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;

                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === "installed") {
                                if (navigator.serviceWorker.controller) {
                                    // Новая версия активирована, страница перезагружается
                                    window.location.reload();
                                }
                            }
                        };
                    };
                })
                .catch((error) => console.error("Ошибка регистрации Service Worker:", error));
        });
    }
}
